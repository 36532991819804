/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCsibasedForm = /* GraphQL */ `
  mutation CreateCsibasedForm(
    $input: CreateCsibasedFormInput!
    $condition: ModelCsibasedFormConditionInput
  ) {
    createCsibasedForm(input: $input, condition: $condition) {
      id
      username
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCsibasedForm = /* GraphQL */ `
  mutation UpdateCsibasedForm(
    $input: UpdateCsibasedFormInput!
    $condition: ModelCsibasedFormConditionInput
  ) {
    updateCsibasedForm(input: $input, condition: $condition) {
      id
      username
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCsibasedForm = /* GraphQL */ `
  mutation DeleteCsibasedForm(
    $input: DeleteCsibasedFormInput!
    $condition: ModelCsibasedFormConditionInput
  ) {
    deleteCsibasedForm(input: $input, condition: $condition) {
      id
      username
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGuestcsibasedForm = /* GraphQL */ `
  mutation CreateGuestcsibasedForm(
    $input: CreateGuestcsibasedFormInput!
    $condition: ModelGuestcsibasedFormConditionInput
  ) {
    createGuestcsibasedForm(input: $input, condition: $condition) {
      id
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGuestcsibasedForm = /* GraphQL */ `
  mutation UpdateGuestcsibasedForm(
    $input: UpdateGuestcsibasedFormInput!
    $condition: ModelGuestcsibasedFormConditionInput
  ) {
    updateGuestcsibasedForm(input: $input, condition: $condition) {
      id
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGuestcsibasedForm = /* GraphQL */ `
  mutation DeleteGuestcsibasedForm(
    $input: DeleteGuestcsibasedFormInput!
    $condition: ModelGuestcsibasedFormConditionInput
  ) {
    deleteGuestcsibasedForm(input: $input, condition: $condition) {
      id
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      account
      points
      dailypoints
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      account
      points
      dailypoints
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      account
      points
      dailypoints
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createServicepredict = /* GraphQL */ `
  mutation CreateServicepredict(
    $input: CreateServicepredictInput!
    $condition: ModelServicepredictConditionInput
  ) {
    createServicepredict(input: $input, condition: $condition) {
      account
      B3
      VISION
      HEARING
      E_WEAR
      E_URINE
      E_TOILET
      E_MOVE
      E_WALK
      CLEANING
      G1A
      G3A
      TALL
      WEIGH
      G4C
      G4D3
      G4F
      G8B
      G8C
      G8E
      H2A
      SOLITARY
      JJ1
      J2
      K2
      K4
      K5
      K6
      K7
      AGE
      A3CH1
      LABOR_TYPE
      storestate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateServicepredict = /* GraphQL */ `
  mutation UpdateServicepredict(
    $input: UpdateServicepredictInput!
    $condition: ModelServicepredictConditionInput
  ) {
    updateServicepredict(input: $input, condition: $condition) {
      account
      B3
      VISION
      HEARING
      E_WEAR
      E_URINE
      E_TOILET
      E_MOVE
      E_WALK
      CLEANING
      G1A
      G3A
      TALL
      WEIGH
      G4C
      G4D3
      G4F
      G8B
      G8C
      G8E
      H2A
      SOLITARY
      JJ1
      J2
      K2
      K4
      K5
      K6
      K7
      AGE
      A3CH1
      LABOR_TYPE
      storestate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteServicepredict = /* GraphQL */ `
  mutation DeleteServicepredict(
    $input: DeleteServicepredictInput!
    $condition: ModelServicepredictConditionInput
  ) {
    deleteServicepredict(input: $input, condition: $condition) {
      account
      B3
      VISION
      HEARING
      E_WEAR
      E_URINE
      E_TOILET
      E_MOVE
      E_WALK
      CLEANING
      G1A
      G3A
      TALL
      WEIGH
      G4C
      G4D3
      G4F
      G8B
      G8C
      G8E
      H2A
      SOLITARY
      JJ1
      J2
      K2
      K4
      K5
      K6
      K7
      AGE
      A3CH1
      LABOR_TYPE
      storestate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createServicepredictresult = /* GraphQL */ `
  mutation CreateServicepredictresult(
    $input: CreateServicepredictresultInput!
    $condition: ModelServicepredictresultConditionInput
  ) {
    createServicepredictresult(input: $input, condition: $condition) {
      id
      account
      BA01
      BA02
      BA03
      BA04
      BA05
      BA07
      BA11
      BA13
      BA14
      BA15
      BA16
      BA17
      BA20
      BB_serv
      BD03
      DA01
      CA07
      GA010209
      GA030407
      OT01
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateServicepredictresult = /* GraphQL */ `
  mutation UpdateServicepredictresult(
    $input: UpdateServicepredictresultInput!
    $condition: ModelServicepredictresultConditionInput
  ) {
    updateServicepredictresult(input: $input, condition: $condition) {
      id
      account
      BA01
      BA02
      BA03
      BA04
      BA05
      BA07
      BA11
      BA13
      BA14
      BA15
      BA16
      BA17
      BA20
      BB_serv
      BD03
      DA01
      CA07
      GA010209
      GA030407
      OT01
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteServicepredictresult = /* GraphQL */ `
  mutation DeleteServicepredictresult(
    $input: DeleteServicepredictresultInput!
    $condition: ModelServicepredictresultConditionInput
  ) {
    deleteServicepredictresult(input: $input, condition: $condition) {
      id
      account
      BA01
      BA02
      BA03
      BA04
      BA05
      BA07
      BA11
      BA13
      BA14
      BA15
      BA16
      BA17
      BA20
      BB_serv
      BD03
      DA01
      CA07
      GA010209
      GA030407
      OT01
      createdAt
      updatedAt
      __typename
    }
  }
`;
