import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { Auth } from 'aws-amplify'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function RegisterPage() {
    const navigate = useNavigate()

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})
    const [isChecked, setIsChecked] = useState(false)

    const [agreeservice, setAgreeservice] = useState(false)
    const [agreeprivacy, setAgreeprivacy] = useState(false)

    const handleAgreeserviceClose = () => setAgreeservice(false);
    const handleAgreeprivacyClose = () => setAgreeprivacy(false);

    const handleChange = () => setIsChecked(!isChecked)

    const handleAgreeserviceshow = () => {
        setAgreeservice(true)
    }

    const handleAgreeprivacyshow = () => {
        setAgreeprivacy(true)
    }

    const setField = (field, value) =>{
        setForm({
            ...form,
            [field]: value
        })
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            })
        }
    }

    const handleRegister = async () => {
        const newErrors = findFormErrors()
        if ( Object.keys(newErrors).length > 0 ){
            setErrors(newErrors)
        }else{
            try {
                form.cellphone = form.cellphone.replace('0','+886')
                await Auth.signUp({
                    username: form.username,
                    password: form.password,
                    attributes: {
                        phone_number: form.cellphone,
                        gender: form.gender,
                        birthdate: form.birthdate,
                        name: form.name,
                    }
                })
                navigate('/validate')            
            } catch (err) { 
                console.log(err.message)
                form.cellphone = form.cellphone.replace('+886','0')
            }
        }
    }

    const findFormErrors = () => {
        const {username, password, confirmpassword, name, gender, birthdate, cellphone, confirm} = form
        const newErrors = {}

        if (!username || username === '' ){
            newErrors.username = '帳號不能空白'
        }else if (username.length < 6 || username.length > 15){
            newErrors.username = '帳號長度請在6~15之間'
        }

        if (!password || password === '' ){
            newErrors.password = '密碼不能空白'
        }else if (!password.match(/(^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$)/)){
            newErrors.password = '密碼長度請在8~20之間且要英數字混和'
        }

        if (!confirmpassword || confirmpassword === '' ){
            newErrors.confirmpassword = '確認密碼不能空白'
        }

        if (password !== confirmpassword ){
            newErrors.password = '  '
            newErrors.confirmpassword = '密碼不同，請重新輸入'
        }

        if (username === password){
            if(username !== '' && password !== '')
                newErrors.username = '  '
                newErrors.password = '帳號密碼請勿相同，請更改您的帳號或密碼'
            if (!username || username === '' ){
                newErrors.username = '帳號不能空白'
            }
            if (!password || password === '' ){
                newErrors.password = '密碼不能空白'
            }
        }

        if (!cellphone || cellphone === '' ){
            newErrors.cellphone = '電話號碼不能空白'
        }else if (!cellphone.match(/09\d{8,8}$/)){
            newErrors.cellphone = '請輸入正確的電話號碼格式'
        }

        if (!name || name === '' ){
            newErrors.name = '姓名不能空白'
        }

        if (!gender || gender === '' ){
            newErrors.gender = '請選擇您的性別'
        }

        if (!birthdate || birthdate === '' ){
            newErrors.birthdate = '請選擇您出生年月日'
        }else{
            let birthdatearr = birthdate.split("-")
            let year = new Date().getFullYear()
            let month = new Date().getMonth() + 1
            let day = new Date().getDate()
            if (Number(birthdatearr[0]) > year){
                newErrors.birthdate = '出生年月日不可以是未來，請重新選擇'
            }else if(Number(birthdatearr[0]) === year && Number(birthdatearr[1]) > month){
                newErrors.birthdate = '出生年月日不可以是未來，請重新選擇'
            }else if(Number(birthdatearr[0]) === year && Number(birthdatearr[1]) === month && Number(birthdatearr[2]) > day){
                newErrors.birthdate = '出生年月日不可以是未來，請重新選擇'
            }
        }
        
        if (!isChecked){
            newErrors.confirm = '您必須同意，否則無法註冊'
        }

        return newErrors
      }

    return (
        <Container>
            <Row className="px-4 my-5">
                <Col><h1>註冊</h1></Col>
            </Row>
            <Row className="px-4 my-5">
                <Col sm={6}>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>帳號</Form.Label>
                            <Form.Control type="text" required
                                onChange={evt => setField('username',evt.target.value)} 
                                isInvalid={!!errors.username}/>
                            <Form.Control.Feedback type='invalid'>{ errors.username }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>密碼</Form.Label>
                            <Form.Control type="password" required
                                onChange={evt => setField('password',evt.target.value)} 
                                isInvalid={!!errors.password}/>
                            <Form.Control.Feedback type='invalid'>{ errors.password }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>確認密碼</Form.Label>
                            <Form.Control type="password" required
                                onChange={evt => setField('confirmpassword',evt.target.value)} 
                                isInvalid={!!errors.confirmpassword}/>
                            <Form.Control.Feedback type='invalid'>{ errors.confirmpassword }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>性別</Form.Label>
                            <Form.Check 
                                type="radio" 
                                label="男" 
                                name="gender" 
                                value='男'
                                onChange={evt => setField('gender',evt.target.value)}
                                isInvalid={!!errors.gender}
                                feedback={errors.gender}
                            />
                            <Form.Check 
                                type="radio" 
                                label="女" 
                                name="gender"  
                                value='女'
                                onChange={evt => setField('gender',evt.target.value)}
                                isInvalid={!!errors.gender}
                                feedback={errors.gender}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>姓名</Form.Label>
                            <Form.Control type="text" required
                                onChange={evt => setField('name',evt.target.value)} 
                                isInvalid={!!errors.name}/>
                            <Form.Control.Feedback type='invalid'>{ errors.name }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>出生年月日</Form.Label>
                            <Form.Control type="date" required
                                onChange={evt => setField('birthdate',evt.target.value)} 
                                isInvalid={!!errors.birthdate}/>
                            <Form.Control.Feedback type='invalid'>{ errors.birthdate }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>電話號碼(請填入正確的電話號碼，否則無法收取驗證碼)</Form.Label>
                            <Form.Control type="text"
                                onChange={evt => setField('cellphone',evt.target.value)} 
                                isInvalid={!!errors.cellphone}/>
                            <Form.Control.Feedback type='invalid'>{ errors.cellphone }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check
                            required
                            label={
                                <>
                                    <span>我同意國衛院智慧長照小幫手</span>
                                    <Button variant="link" onClick={handleAgreeserviceshow}>使用者服務條款</Button>
                                    <span>與</span>
                                    <Button variant="link" onClick={handleAgreeprivacyshow}>使用者隱私權政策</Button>
                                </>
                            }
                            feedback={errors.confirm}
                            feedbackType="invalid"
                            checked={isChecked}
                            onChange={handleChange}
                            isInvalid={!isChecked && !!errors.confirm}
                            readOnly/>
                        </Form.Group>

                        <Button variant="primary" type="button"
                        onClick={handleRegister}>註冊</Button>

                        <Modal show={agreeservice} onHide={handleAgreeserviceClose} centered size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>使用者服務條款</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>歡迎您使用<b>國衛院智慧長照小幫手</b>。本網站提供照顧負荷評估及長照服務預測服務。在您註冊成為我們網站的會員之前，請仔細閱讀以下免責聲明：</p>
                                <p>1.   評估及預測結果僅供參考<br/>
                                    本網站上提供的評估及預測結果基於學術上演算法和/或資料分析，僅供您參考。我們不保證預測結果的準確性、完整性或時效性。
                                </p>
                                <p>2.   無保證聲明<br/>
                                    我們不對評估及預測結果做出任何明示或暗示的保證，包括但不限於特定目的的適用性的保證。
                                </p>
                                <p>3.   風險承擔<br/>
                                    您理解並同意，您依賴任何通過本網站獲得的評估及預測結果所產生的風險由您自行承擔。
                                </p>
                                <p>4.	不構成專業建議<br/>
                                    本網站上的評估及預測結果不構成任何形式的專業建議。如需專業建議，我們建議您聯絡相關領域的專業人士。
                                </p>
                                <p>在註冊成為<b>國衛院智慧長照小幫手</b>的會員或使用我們的評估及預測服務之前，請確保您已經明白並同意以上免責聲明。如果您不同意上述任何條款，請勿註冊或使用本網站。</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={handleAgreeserviceClose}>關閉</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={agreeprivacy} onHide={handleAgreeprivacyClose} centered size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>使用者隱私權政策</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>1.   使用者資料蒐集與使用<br/>
                                    我們在您註冊成為我們網站會員時蒐集您的姓名、電話號碼和密碼。您提供的所有資料都會依照個人資料保護法之規定嚴格保密。我們會使用您提供的資料來管理您的帳戶，提供您請求的服務，以及改善我們的服務。
                                </p>
                                <p>2.   使用者資料分享與披露<br/>
                                    除非得到您的許可，否則我們不會與第三方分享您的個人資料。我們可能會在法律要求或者為了保護我們的權利和財產時分享您的個人資料。
                                </p>
                                <p>3.   安全<br/>
                                    我們採取一切必要的措施來保護您的個人資料，以防止未經授權的人士訪問、披露、更改或銷毀您的資料。
                                </p>
                                <p>4.	Cookie和追踪技術<br/>
                                    我們的網站可能會使用cookies和其他追踪技術來改善用戶體驗並分析網站流量。
                                </p>
                                <p>5.	第三方網站<br/>
                                    本隱私政策不適用於我們網站上連結的第三方網站。我們不對這些網站的隱私實務負責。
                                </p>
                                <p>6.	隱私政策的變更<br/>
                                    我們保留隨時更新本隱私政策的權利。任何更改將在我們的網站上公佈，並在適用法律要求的情況下通知您。
                                </p>
                                <p>7.	聯絡我們<br/>
                                    如果您對我們的隱私政策有任何疑問或建議，請透過email(mailto: rchung@nhri.edu.tw)與我們聯絡。
                                </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={handleAgreeprivacyClose}>關閉</Button>
                            </Modal.Footer>
                        </Modal>

                        &nbsp;&nbsp;
                        <Link
                            to='/login'>
                            <Button variant="outline-primary">返回登入</Button>
                        </Link>
                        &nbsp;&nbsp;
                        <Link
                            to='/'>
                            <Button variant="outline-primary">回首頁</Button>
                        </Link>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default RegisterPage;