/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCsibasedForm = /* GraphQL */ `
  query GetCsibasedForm($id: ID!) {
    getCsibasedForm(id: $id) {
      id
      username
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCsibasedForms = /* GraphQL */ `
  query ListCsibasedForms(
    $filter: ModelCsibasedFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCsibasedForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        score
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGuestcsibasedForm = /* GraphQL */ `
  query GetGuestcsibasedForm($id: ID!) {
    getGuestcsibasedForm(id: $id) {
      id
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGuestcsibasedForms = /* GraphQL */ `
  query ListGuestcsibasedForms(
    $filter: ModelGuestcsibasedFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGuestcsibasedForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        score
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      account
      points
      dailypoints
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        points
        dailypoints
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getServicepredict = /* GraphQL */ `
  query GetServicepredict($account: String!) {
    getServicepredict(account: $account) {
      account
      B3
      VISION
      HEARING
      E_WEAR
      E_URINE
      E_TOILET
      E_MOVE
      E_WALK
      CLEANING
      G1A
      G3A
      TALL
      WEIGH
      G4C
      G4D3
      G4F
      G8B
      G8C
      G8E
      H2A
      SOLITARY
      JJ1
      J2
      K2
      K4
      K5
      K6
      K7
      AGE
      A3CH1
      LABOR_TYPE
      storestate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listServicepredicts = /* GraphQL */ `
  query ListServicepredicts(
    $account: String
    $filter: ModelServicepredictFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServicepredicts(
      account: $account
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        account
        B3
        VISION
        HEARING
        E_WEAR
        E_URINE
        E_TOILET
        E_MOVE
        E_WALK
        CLEANING
        G1A
        G3A
        TALL
        WEIGH
        G4C
        G4D3
        G4F
        G8B
        G8C
        G8E
        H2A
        SOLITARY
        JJ1
        J2
        K2
        K4
        K5
        K6
        K7
        AGE
        A3CH1
        LABOR_TYPE
        storestate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getServicepredictresult = /* GraphQL */ `
  query GetServicepredictresult($id: ID!) {
    getServicepredictresult(id: $id) {
      id
      account
      BA01
      BA02
      BA03
      BA04
      BA05
      BA07
      BA11
      BA13
      BA14
      BA15
      BA16
      BA17
      BA20
      BB_serv
      BD03
      DA01
      CA07
      GA010209
      GA030407
      OT01
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listServicepredictresults = /* GraphQL */ `
  query ListServicepredictresults(
    $filter: ModelServicepredictresultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServicepredictresults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        BA01
        BA02
        BA03
        BA04
        BA05
        BA07
        BA11
        BA13
        BA14
        BA15
        BA16
        BA17
        BA20
        BB_serv
        BD03
        DA01
        CA07
        GA010209
        GA030407
        OT01
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const csibasedFormsByUsername = /* GraphQL */ `
  query CsibasedFormsByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelcsibasedFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    csibasedFormsByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        score
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByAccount = /* GraphQL */ `
  query UsersByAccount(
    $account: String!
    $sortDirection: ModelSortDirection
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByAccount(
      account: $account
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        points
        dailypoints
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const servicepredictresultsByAccount = /* GraphQL */ `
  query ServicepredictresultsByAccount(
    $account: String!
    $sortDirection: ModelSortDirection
    $filter: ModelservicepredictresultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    servicepredictresultsByAccount(
      account: $account
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        BA01
        BA02
        BA03
        BA04
        BA05
        BA07
        BA11
        BA13
        BA14
        BA15
        BA16
        BA17
        BA20
        BB_serv
        BD03
        DA01
        CA07
        GA010209
        GA030407
        OT01
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
