import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { servicepredictresultsByAccount } from '../../graphql/queries'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'

import CustomModal from '../common/CustomModal'


function ServicePredictresultPage (props){
    const navigate = useNavigate()

    const [results, setResults] = useState([])
    const [resultsState, setResultsState] = useState(false)

    const getresults = async () => {
        try{
            const user = await Auth.currentAuthenticatedUser()
            const username = user.username
            const listresultsData = await API.graphql(graphqlOperation(servicepredictresultsByAccount,{account:username}))
            const resultslist = listresultsData.data.servicepredictresultsByAccount.items
            if (resultslist !== null){
                for(let i=0;i<resultslist.length;i++){
                    for(let j=i+1;j<resultslist.length;j++){
                        if(resultslist[i].createdAt > resultslist[j].createdAt){
                            let temp = resultslist[i]
                            resultslist[i] = resultslist[j]
                            resultslist[j] = temp
                        }
                    }
                }
                setResults(resultslist)
                setResultsState(true)
            }
        }catch(err){
            console.log('fail')
            console.error(err)
        }
    }
    
    const getTime = (createtime) => {
        const datetime = new Date(createtime).toLocaleString()
        return datetime
    }

    useEffect(() => {
        {
            props.isAuthenticated !== true && (
                navigate('/')
            )
            getresults()
        }
    }, []);

    if(resultsState){
        if(results.length !== 0){
            return(
                <Container>
                    <Row className="px-4 my-5">
                        <Col sm={12}>
                            {
                                results.map((result,index) =>{
                                    return(
                                        <Accordion>
                                        <Accordion.Item eventKey={index+1}>
                                        <Accordion.Header>此為您第{index+1}次服務預測結果紀錄，點選查看結果，儲存時間：{getTime(result.createdAt)}</Accordion.Header>
                                        <Accordion.Body>
                                            {result.BA01 !== "0" && <h3>基本身體清潔<CustomModal content="/服務預測結果字卡BA01.png" /></h3>}
                                            {result.BA02 !== "0" && <h3>基本日常照顧<CustomModal content="/服務預測結果字卡BA02.png" /></h3>}
                                            {result.BA03 !== "0" && <h3>測量生命跡象<CustomModal content="/服務預測結果字卡BA03.png" /></h3>}
                                            {result.BA04 !== "0" && <h3>協助進食或管灌餵食<CustomModal content="/服務預測結果字卡BA04.png" /></h3>}
                                            {result.BA05 !== "0" && <h3>餐食照顧<CustomModal content="/服務預測結果字卡BA05.png" /></h3>}
                                            {result.BA07 !== "0" && <h3>協助沐浴及洗頭<CustomModal content="/服務預測結果字卡BA07.png" /></h3>}
                                            {result.BA11 !== "0" && <h3>肢體關節活動<CustomModal content="/服務預測結果字卡BA11.png" /></h3>}
                                            {result.BA13 !== "0" && <h3>陪同外出<CustomModal content="/服務預測結果字卡BA13.png" /></h3>}
                                            {result.BA14 !== "0" && <h3>陪同就醫<CustomModal content="/服務預測結果字卡BA14.png" /></h3>}
                                            {result.BA15 !== "0" && <h3>居家服務<CustomModal content="/服務預測結果字卡BA15.png" /></h3>}
                                            {result.BA16 !== "0" && <h3>代購或代領或代送服務<CustomModal content="/服務預測結果字卡BA16.png" /></h3>}
                                            {result.BA17 !== "0" && <h3>協助執行輔助性醫療<CustomModal content="/服務預測結果字卡BA17.png" /></h3>}
                                            {result.BA20 !== "0" && <h3>陪伴服務<CustomModal content="/服務預測結果字卡BA20.png" /></h3>}
                                            {result.BB_serv !== "0" && <h3>日間照顧<CustomModal content="/服務預測結果字卡BB_serv.png" /></h3>}
                                            {result.BD03 !== "0" && <h3>社區式服務交通接送<CustomModal content="/服務預測結果字卡BD03.png" /></h3>}
                                            {result.DA01 !== "0" && <h3>交通接送<CustomModal content="/服務預測結果字卡DA01.png" /></h3>}
                                            {result.CA07 !== "0" && <h3>IADLs復能、ADLs復能照護<CustomModal content="/服務預測結果字卡CA07.png" /></h3>}
                                            {result.GA010209 !== "0" && <h3>居家喘息服務<CustomModal content="/服務預測結果字卡GA010209.png" /></h3>}
                                            {result.GA030407 !== "0" && <h3>日間照顧中心喘息服務、巷弄長照站臨托<CustomModal content="/服務預測結果字卡GA030407.png" /></h3>}
                                            {result.OT01 !== "0" && <h3>營養餐飲<CustomModal content="/服務預測結果字卡OT01.png" /></h3>}
                                        </Accordion.Body>
                                        </Accordion.Item>
                                        </Accordion>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </Container>
            )
        }else{
            return(
                <Container>
                    <Row className="px-4 my-5">
                        <Col sm={12}>
                            <h3>您目前尚無紀錄</h3>
                        </Col>
                    </Row>
                </Container>
            )
        }
    }else{
        return(
            <Container>
                <Row className="px-4 my-5">
                    <Col sm={12}>
                        <h3>正在抓取您的資料中</h3>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default ServicePredictresultPage;