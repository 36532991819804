import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { Auth } from 'aws-amplify';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function ForgetPasswordResetPage() {
    const navigate = useNavigate()
    
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const setField = (field, value) =>{
        setForm({
            ...form,
            [field]: value
        })
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            })
        }
    }

    const forgotPassword = async () => {
        const newErrors = findFormErrors()
        if ( Object.keys(newErrors).length > 0 ){
            setErrors(newErrors)
        }else{
            try {

                await Auth.forgotPasswordSubmit(form.username, form.authenticationCode, form.newpassword)
                navigate('/login')
            } catch (err) {
                 console.log(err.message)    
            }
        }
    }

    const findFormErrors = () => {
        const {username, newpassword, confirmnewpassword, authenticationCode} = form
        const newErrors = {}

        if (!username || username === '' ){
            newErrors.username = '帳號不能空白'
        }

        if (!newpassword || newpassword === '' ){
            newErrors.newpassword = '密碼不能空白'
        }else if (!newpassword.match(/(^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$)/)){
            newErrors.newpassword = '密碼長度請在8~20之間且要英數字混和'
        }

        if (newpassword !== confirmnewpassword){
            if (!newpassword.match(/(^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$)/)){
                newErrors.newpassword = '密碼長度請在8~20之間且要英數字混和'
            }else{
                newErrors.newpassword = '  '
                newErrors.confirmnewpassword = '密碼不同，請重新輸入'
            }
        }

        if (username === newpassword){
            if(username !== '' && newpassword !== '')
                newErrors.username = '  '
                newErrors.newpassword = '帳號密碼請勿相同，請更改您的密碼'
            if (!username || username === '' ){
                newErrors.username = '帳號不能空白'
            }
            if (!newpassword || newpassword === '' ){
                newErrors.newpassword = '密碼不能空白'
            }
        }

        if (!authenticationCode || authenticationCode === '' ){
            newErrors.authenticationCode = '驗證碼不能空白'
        }

        return newErrors
      }

    return (
        <Container>
            <Row className="px-4 my-5">
                <Col><h2>請到您的簡訊收取驗證碼(如未收到，請等待一段時間)</h2></Col>
            </Row>
            <Row className="px-4 my-5">
                <Col sm={6}>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>帳號</Form.Label>
                            <Form.Control type="text"
                                onChange={evt => setField('username',evt.target.value)} 
                                isInvalid={!!errors.username}/>
                            <Form.Control.Feedback type='invalid'>{ errors.username }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>新密碼</Form.Label>
                            <Form.Control type="password"
                                onChange={evt => setField('newpassword',evt.target.value)} 
                                isInvalid={!!errors.newpassword}/>
                            <Form.Control.Feedback type='invalid'>{ errors.newpassword }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>確認新密碼</Form.Label>
                            <Form.Control type="password"
                                onChange={evt => setField('confirmnewpassword',evt.target.value)} 
                                isInvalid={!!errors.confirmnewpassword}/>
                            <Form.Control.Feedback type='invalid'>{ errors.confirmnewpassword }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>驗證碼</Form.Label>
                            <Form.Control type="text"
                                onChange={evt => setField('authenticationCode',evt.target.value)} 
                                isInvalid={!!errors.authenticationCode}/>
                            <Form.Control.Feedback type='invalid'>{ errors.authenticationCode }</Form.Control.Feedback>
                        </Form.Group>
                        
                        <Button variant="primary" type="button"
                            onClick={forgotPassword}>送出</Button>
                        &nbsp;&nbsp;
                        <Link
                            to='/login'>
                            <Button variant="outline-primary">返回登入</Button>
                        </Link>
                        &nbsp;&nbsp;
                        <Link
                            to='/'>
                            <Button variant="outline-primary">回首頁</Button>
                        </Link>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default ForgetPasswordResetPage;