import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CustomModal from '../common/CustomModal';

import { createServicepredictresult, updateServicepredict } from '../../graphql/mutations'
import { getServicepredict } from '../../graphql/queries';
import { Auth, API, graphqlOperation } from 'aws-amplify';

function ServicePredictPage(props) {
    const navigate = useNavigate()

    const [servicedataState, setServiceDataState] = useState(false)
    const [servicedata,setServiceData] = useState([])

    const [servicestoreState, setStoreState] = useState(false)

    const [storeshow, setStoreShow] = useState(false)
    const handleStoreShow = () => setStoreShow(true)
    const handleStoreClose = () => setStoreShow(false)
    

    const getData = async () => {
        const user = await Auth.currentAuthenticatedUser()
        const data = await API.post('predictservice', '/service',{ 
            body: { 
              account: user.username
            } 
        })
        if(data !== null){
            setServiceDataState(true)
            setServiceData(data)
        }
    }

    const getstorestate = async () => {
        try{
            const user = await Auth.currentAuthenticatedUser()
            const listData = await API.graphql(graphqlOperation(getServicepredict,{account:user.username}))
            setStoreState(listData.data.getServicepredict.storestate)
        }catch(err){
            console.log('fail')
            console.error(err)
        }
    }

    const handlestore = async () => {
        const user = await Auth.currentAuthenticatedUser()
        try {
            const newresultdata = {
                account: user.username,
                BA01: servicedata.result.BA01,
                BA02: servicedata.result.BA02,
                BA03: servicedata.result.BA03,
                BA04: servicedata.result.BA04,
                BA05: servicedata.result.BA05,
                BA07: servicedata.result.BA07,
                BA11: servicedata.result.BA11,
                BA13: servicedata.result.BA13,
                BA14: servicedata.result.BA14,
                BA15: servicedata.result.BA15,
                BA16: servicedata.result.BA16,
                BA17: servicedata.result.BA17,
                BA20: servicedata.result.BA20,
                BB_serv: servicedata.result.BB_serv,
                BD03: servicedata.result.BD03,
                DA01: servicedata.result.DA01,
                CA07: servicedata.result.CA07,
                GA010209: servicedata.result.GA010209,
                GA030407: servicedata.result.GA030407,
                OT01: servicedata.result.OT01
            }
            const updatestorestate = {
                account: user.username,
                storestate: true
            }
            console.log('store')
            await API.graphql(graphqlOperation(createServicepredictresult, {input: newresultdata}));
            await API.graphql(graphqlOperation(updateServicepredict, {input: updatestorestate}));
            setStoreShow(false)
            navigate('/servicepredictresult')
        } catch (err) { 
            console.log('error') 
            console.error(err) 
        }
    }

    useEffect(() => {
        {
            props.isAuthenticated !== true && (
                navigate('/')
            )
            getData()
            getstorestate()
        }
    }, []);

    if(servicedataState){
        if(servicedata.result !== '目前尚無資料'){
            return (
                <Container>
                    <Row className="px-4 my-5">
                        <Col sm={12}>
                            <h1 className="font-weight-light">此為您的預測結果，可能需要的服務如下</h1>
                            <br/>
                            {servicedata.result.BA01 !== 0 && 
                                <h3>基本身體清潔<CustomModal content="/服務預測結果字卡BA01.png" /></h3>}
                            {servicedata.result.BA02 !== 0 && 
                                <h3>基本日常照顧<CustomModal content="/服務預測結果字卡BA02.png" /></h3>}
                            {servicedata.result.BA03 !== 0 && 
                                <h3>測量生命跡象<CustomModal content="/服務預測結果字卡BA03.png" /></h3>}
                            {servicedata.result.BA04 !== 0 && 
                                <h3>協助進食或管灌餵食<CustomModal content="/服務預測結果字卡BA04.png" /></h3>}
                            {servicedata.result.BA05 !== 0 && 
                                <h3>餐食照顧<CustomModal content="/服務預測結果字卡BA05.png" /></h3>}
                            {servicedata.result.BA07 !== 0 && 
                                <h3>協助沐浴及洗頭<CustomModal content="/服務預測結果字卡BA07.png" /></h3>}
                            {servicedata.result.BA11 !== 0 && 
                                <h3>肢體關節活動<CustomModal content="/服務預測結果字卡BA11.png" /></h3>}
                            {servicedata.result.BA13 !== 0 && 
                                <h3>陪同外出<CustomModal content="/服務預測結果字卡BA13.png" /></h3>}
                            {servicedata.result.BA14 !== 0 && 
                                <h3>陪同就醫<CustomModal content="/服務預測結果字卡BA14.png" /></h3>}
                            {servicedata.result.BA15 !== 0 && 
                                <h3>居家服務<CustomModal content="/服務預測結果字卡BA15.png" /></h3>}
                            {servicedata.result.BA16 !== 0 && 
                                <h3>代購或代領或代送服務<CustomModal content="/服務預測結果字卡BA16.png" /></h3>}
                            {servicedata.result.BA17 !== 0 && 
                                <h3>協助執行輔助性醫療<CustomModal content="/服務預測結果字卡BA17.png" /></h3>}
                            {servicedata.result.BA20 !== 0 && 
                                <h3>陪伴服務<CustomModal content="/服務預測結果字卡BA20.png" /></h3>}
                            {servicedata.result.BB_serv !== 0 && 
                                <h3>日間照顧<CustomModal content="/服務預測結果字卡BB_serv.png" /></h3>}
                            {servicedata.result.BD03 !== 0 && 
                                <h3>社區式服務交通接送<CustomModal content="/服務預測結果字卡BD03.png" /></h3>}
                            {servicedata.result.DA01 !== 0 && 
                                <h3>交通接送<CustomModal content="/服務預測結果字卡DA01.png" /></h3>}
                            {servicedata.result.CA07 !== 0 && 
                                <h3>IADLs復能、ADLs復能照護<CustomModal content="/服務預測結果字卡CA07.png" /></h3>}
                            {servicedata.result.GA010209 !== 0 && 
                                <h3>居家喘息服務<CustomModal content="/服務預測結果字卡GA010209.png" /></h3>}
                            {servicedata.result.GA030407 !== 0 && 
                                <h3>日間照顧中心喘息服務、巷弄長照站臨托<CustomModal content="/服務預測結果字卡GA030407.png" /></h3>}
                            {servicedata.result.OT01 !== 0 && 
                                <h3>營養餐飲<CustomModal content="/服務預測結果字卡OT01.png" /></h3>}
                            {servicestoreState !== true && 
                                <Button variant="outline-primary" onClick={handleStoreShow}>預測結果儲存</Button>}
                            <Modal show={storeshow} onHide={handleStoreClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>是否要儲存此預測結果</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Button variant="outline-primary" onClick={handleStoreClose}>否</Button>
                                &nbsp;&nbsp;
                                <Button variant="primary" onClick={handlestore}>是</Button>
                            </Modal.Body>
                            </Modal>
                        </Col>
                    </Row>
                </Container >
            )
        }else{
            return (
                <Container>
                    <Row className="px-4 my-5">
                        <Col sm={6}>
                            <h1 className="font-weight-light">目前尚無資料</h1>
                        </Col>
                    </Row>
                </Container >
            )
        }
    }else{
        return (
            <Container>
                <Row className="px-4 my-5">
                    <Col sm={6}>
                        <h1 className="font-weight-light">正在預測中</h1>
                    </Col>
                </Row>
            </Container >
        )
    }
}

export default ServicePredictPage;