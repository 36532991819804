import './App.css'

import { Amplify, Auth } from 'aws-amplify'
import { Routes, Route } from 'react-router-dom'
import { useState,useEffect } from 'react'

import SiteNav from './components/common/SiteNav'
import SiteFooter from './components/common/SiteFooter'
import HomePage from './components/home/HomePage'
import LoginPage from './components/auth/LoginPage'
import RegisterPage from './components/auth/RegisterPage'
import ValidatePage from './components/auth/ValidatePage'
import ForgetPasswordPage from './components/auth/ForgetPasswordPage'
import ForgetPasswordResetPage from './components/auth/ForgetPasswordResetPage'
import GuestFormPage from './components/form/GuestForm'
import FormPage from './components/form/Form'
import FormRecords from './components/form/FormRecords'
import ServiceFormPage from './components/form/ServiceForm'
import ChangeServiceFormPage from './components/form/ChangeServiceForm'
import ServicePredictPage from './components/form/ServicePredict'
import ServicePredictresultPage from './components/form/ServicePredictresult'
import GuestServicePage from './components/form/GuestService'

import awsExports from './aws-exports';

Amplify.configure(awsExports);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(true)

  function updateAuthStatus(authStatus) {
    setIsAuthenticated(authStatus)
  }

  useEffect(() => {
    onLoad();
  },[])

  async function onLoad() {
    try {
      await Auth.currentSession();
      setIsAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }
    setIsAuthenticating(false);
  }

  return (
      !isAuthenticating && (
        <div>
          <SiteNav isAuthenticated={isAuthenticated} updateAuthStatus={updateAuthStatus} />
          <Routes>
            <Route path='/login' element = {<LoginPage updateAuthStatus={updateAuthStatus} />} />
            <Route path='/register' element = {<RegisterPage/>}/>
            <Route path='/forgetpassword' element = {<ForgetPasswordPage/>}/>
            <Route path='/forgetpasswordreset' element = {<ForgetPasswordResetPage/>}/>
            <Route path='/validate' element={<ValidatePage />} />
            <Route path='/guestCSIbasedform' element={<GuestFormPage />} />
            <Route path='/guestserviceform' element={<GuestServicePage />} />
            <Route path='/CSIbasedform' element = {<FormPage isAuthenticated={isAuthenticated} />} />
            <Route path='/CSIbasedformrecords' element = {<FormRecords isAuthenticated={isAuthenticated} />} />
            <Route path='/serviceform' element = {<ServiceFormPage isAuthenticated={isAuthenticated} />} />
            <Route path='/changeserviceform' element = {<ChangeServiceFormPage isAuthenticated={isAuthenticated} />} />
            <Route path='/servicepredict' element = {<ServicePredictPage isAuthenticated={isAuthenticated} />} />
            <Route path='/servicepredictresult' element = {<ServicePredictresultPage isAuthenticated={isAuthenticated} />} />
            <Route path='/' exact={true} element={<HomePage isAuthenticated={isAuthenticated} />} />
            <Route path='*' element={<HomePage isAuthenticated={isAuthenticated} />} />
          </Routes>
          {/*<SiteFooter/>*/}
        </div>
      )
  )
}

export default App;
