import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { csibasedFormsByUsername } from '../../graphql/queries'

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import Data from '../../data.csv'
import Papa from 'papaparse'


function FormRecords (props){
    const navigate = useNavigate()

    const [forms, setForms] = useState([])
    const [formsState, setFormsState] = useState(false)
    const [data, setData] = useState([])
    const [birthdate, setBirthdate] = useState(0)
    const [gender, setGenger] = useState('')
    const [age_group, setAge_group] = useState('')
    let linechardata = []

    const getForms = async () => {
        try{
            const user = await Auth.currentAuthenticatedUser()
            const username = user.username
            const userBirthdate = user.attributes.birthdate
            const userGender = user.attributes.gender
            setBirthdate(calculateAge(userBirthdate))
            setGenger(userGender)
            const listformsData = await API.graphql(graphqlOperation(csibasedFormsByUsername,{username:username}))
            const formslist = listformsData.data.csibasedFormsByUsername.items
            if (formslist !== null){
                for(let i=0;i<formslist.length;i++){
                    for(let j=i+1;j<formslist.length;j++){
                        if(formslist[i].createdAt > formslist[j].createdAt){
                            let temp = formslist[i]
                            formslist[i] = formslist[j]
                            formslist[j] = temp
                        }
                    }
                }
                setForms(formslist)
            }
            setFormsState(true)
        }catch(err){
            console.log('fail')
            console.error(err)
        }
    }

    const calculateAge = (birthdate) => {
        const ageDifMs = Date.now() - new Date(birthdate).getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
    
    const getTime = (createtime) => {
        const datetime = new Date(createtime).toLocaleString()
        return datetime
    }

    useEffect(() => {
        {
            getForms()
            props.isAuthenticated !== true && (
                navigate('/')
            )
            const fetchData = async () => {
                const response = await fetch(Data)
                const reader = response.body.getReader()
                const result = await reader.read()
                const decoder = new TextDecoder("utf-8")
                const csvData = decoder.decode(result.value)
                const parsedData = Papa.parse(csvData, { 
                  header: true, 
                  skipEmptyLines: true 
                }).data;
                setData(parsedData)
              };
              fetchData()
              Classification()
        }
    }, [formsState]);
    const Classification = () => {
        if (gender === '男'){
            if (birthdate <= 30){
                setAge_group('18-30')
            }else if(birthdate >= 31 && birthdate <=50){
                setAge_group('31-50')
            }else if(birthdate >= 51 && birthdate <=65){
                setAge_group('51-65')
            }else{
                setAge_group('66-99')
            }
        }else{
            if (birthdate <= 30){
                setAge_group('18-30')
            }else if(birthdate >= 31 && birthdate <=50){
                setAge_group('31-50')
            }else if(birthdate >= 51 && birthdate <=65){
                setAge_group('51-65')
            }else{
                setAge_group('66-99')
            }
        }
    }

    const calculateScoreindex = (index,score) =>{
        let scoreindex = 1
        for (let i=0;i<=data.length-1;i++){
            if(age_group === data[i].Caregiver_AgeGroup && gender === data[i].Caregiver_SEX && score > data[i].CSI_TOTAL_SCORE){
                scoreindex += 1
            }
        }
        linechardata.push({'name':'第'+(index+1)+'次','照顧負荷%數約':scoreindex})
        return scoreindex
    }

    if(formsState){
        if(forms.length !== 0){
            return(
                <Container>
                    <Row className="px-4 my-5">
                        <Col sm={12}>
                            {
                                forms.map((form,index) =>{
                                    return(
                                        <Accordion>
                                        <Accordion.Item eventKey={index+1}>
                                        <Accordion.Header>此為您第{index+1}次負荷指數評估紀錄，點選查看結果，填寫時間：{getTime(form.createdAt)}</Accordion.Header>
                                        <Accordion.Body>
                                            <b><h3>與您年齡相近且同樣為{gender}性的照顧者中，您的照顧負荷高於{calculateScoreindex(index,form.score)}%的人</h3></b>
                                            <b><p style={{color : 'red',fontSize: '25px'}}>※請注意，比率越高代表您的負荷也越大</p></b>
                                            <a href="https://carersupport.com.tw/" target="_blank" style={{fontSize: '20px'}}>您累了嗎？點選取得家庭照顧者服務資源</a>
                                            <br/><br/>
                                            <p>問題1.若您有事必須外出，一天之中（二十四小時），請問您可將個案單獨留置家中幾小時沒有問題？</p>
                                            {form.Q1 === "0" && (<p>您的答案為:九小時以上</p>)}
                                            {form.Q1 === "0.2" && (<p>您的答案為:六小時～未滿九小時</p>)}
                                            {form.Q1 === "0.4" && (<p>您的答案為:三小時～未滿六小時</p>)}
                                            {form.Q1 === "0.6" && (<p>您的答案為:一小時～未滿三小時</p>)}
                                            {form.Q1 === "0.8" && (<p>您的答案為:未滿一小時</p>)}
                                            {form.Q1 === "1" && (<p>您的答案為:個案不能一個人在家</p>)}
                                            <p>問題2.體力上的負荷(例如：需專注看護病者或費力去幫助病者坐立)</p>
                                            {form.Q2 === "0" && (<p>您的答案為:否</p>)}
                                            {form.Q2 === "1" && (<p>您的答案為:是</p>)}
                                            <p>問題3.睡眠受到干擾(例如：需在夜間照顧經常要上落床或無法安頓下來的病者)</p>
                                            {form.Q3 === "0" && (<p>您的答案為:否</p>)}
                                            {form.Q3 === "1" && (<p>您的答案為:是</p>)}
                                            <p>問題4.整體來說，您覺得您生活品質好不好？</p>
                                            {form.Q4 === "0" && (<p>您的答案為:非常好</p>)}
                                            {form.Q4 === "0.2" && (<p>您的答案為:很好</p>)}
                                            {form.Q4 === "0.4" && (<p>您的答案為:好</p>)}
                                            {form.Q4 === "0.6" && (<p>您的答案為:尚可</p>)}
                                            {form.Q4 === "0.8" && (<p>您的答案為:不好</p>)}
                                            {form.Q4 === "1" && (<p>您的答案為:非常不好</p>)}
                                            <p>問題5.需要分配時間照顧其他家人</p>
                                            {form.Q5 === "0" && (<p>您的答案為:否</p>)}
                                            {form.Q5 === "1" && (<p>您的答案為:是</p>)}
                                            <p>問題6.對個案的行為感到困擾(例如：失禁、他記憶事情有困難或他怪責別人取了他的東西)</p>
                                            {form.Q6 === "0" && (<p>您的答案為:否</p>)}
                                            {form.Q6 === "1" && (<p>您的答案為:是</p>)}
                                            <p>問題7.發現病者改變很大而令你不安</p>
                                            {form.Q7 === "0" && (<p>您的答案為:否</p>)}
                                            {form.Q7 === "1" && (<p>您的答案為:是</p>)}
                                            <p>問題8.為了照顧個案，您的工作是否受到影響</p>
                                            {form.Q8 === "0" && (<p>您的答案為:否</p>)}
                                            {form.Q8 === "1" && (<p>您的答案為:是</p>)}
                                            <p>問題9.是否感到心靈疲累(例如：因為擔心或顧慮該如何處理病者)</p>
                                            {form.Q9 === "0" && (<p>您的答案為:否</p>)}
                                            {form.Q9 === "1" && (<p>您的答案為:是</p>)}
                                        </Accordion.Body>
                                        </Accordion.Item>
                                        </Accordion>
                                    )
                                })
                            }
                            {
                                linechardata.length > 1 && (
                                    <>
                                        <br></br>
                                        <br></br>
                                        <h3>此為您的照顧負荷趨勢圖</h3>
                                        <ResponsiveContainer width="100%" height="50%">
                                            <LineChart width={500} height={300} data={linechardata} margin={{ top: 10, right: 20, bottom: 5, left: 0 }}>
                                                <Line type="monotone" dataKey="照顧負荷%數約" stroke="#8884d8" />
                                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </>
                                )
                            }
                        </Col>
                    </Row>
                </Container>
            )
        }else{
            return(
                <Container>
                    <Row className="px-4 my-5">
                        <Col sm={12}>
                            <h3>您目前尚無紀錄</h3>
                        </Col>
                    </Row>
                </Container>
            )
        }
    }else{
        return(
            <Container>
                <Row className="px-4 my-5">
                    <Col sm={12}>
                        <h3>正在抓取您的資料中</h3>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default FormRecords;