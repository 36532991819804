import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const CustiomModal = ({ content, size = 'lg'}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="link" size='lg' onClick={handleShow}>
        服務介紹
      </Button>

      <Modal show={show} onHide={handleClose} size={size}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <img src={content} alt="Image" style={{ width: '100%' }} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustiomModal;