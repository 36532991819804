import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { createUser } from '../../graphql/mutations'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { v4 as uuid } from 'uuid'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function ValidatePage() {
    const navigate = useNavigate()
    
    const [username, setUserName] = useState('');
    const [authenticationCode, setAuthenticationCode] = useState('');
    const [usernameblankstate, setusernameblankState] = useState(true)
    const [authenticationCodeblankstate, setauthenticationCodeblankState] = useState(true)
    const [errorstate, seterrorState] = useState(true)
    
    const [resendstate, setResendState] = useState(false)

    const handleRegisterConfirmation = async () => {
        try {

            await Auth.confirmSignUp(username, authenticationCode)
            const newformdata = {
                id: uuid(),
                account: username,
                points: 0,
                dailypoints: 0
            }
            await API.graphql(graphqlOperation(createUser, {input: newformdata}));
            navigate('/login')
        } catch (err) {
            if (username === ''){
                setusernameblankState(false)
                setauthenticationCodeblankState(true)
                seterrorState(true)
            }else if (username !== '' && authenticationCode === ''){
                setusernameblankState(true)
                setauthenticationCodeblankState(false)
                seterrorState(true)
            }else{
                setusernameblankState(true)
                setauthenticationCodeblankState(true)
                seterrorState(false)
            }
            setResendState(false)
        }
    }

    const resendAuthenticationCode = async () => {
        try{
            await Auth.resendSignUp(username)
            setResendState(false)
        }catch(err){
            if (username === ''){
                setResendState(true)
                
            }else{
                setResendState(false)
                
            }
            setusernameblankState(true)
            setauthenticationCodeblankState(true)
            seterrorState(true)
        }
    }

    return (
        <Container>
            <Row className="px-4 my-5">
                <Col><h2>請到您的簡訊收取驗證碼(如未收到，請等待一段時間)</h2></Col>
            </Row>
            <Row className="px-4 my-5">
                <Col sm={6}>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>帳號</Form.Label>
                            <Form.Control type="text"
                                onChange={evt => setUserName(evt.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>驗證碼</Form.Label>
                            <Form.Control type="text"
                                onChange={evt => setAuthenticationCode(evt.target.value)} />
                        </Form.Group>

                        {(!usernameblankstate) && <p style={{color:'red'}}>帳號不得空白</p>}
                        {(!authenticationCodeblankstate) && <p style={{color:'red'}}>驗證碼不得空白</p>}
                        {(!errorstate) && <p style={{color:'red'}}>帳號或驗證碼錯誤，請重新輸入</p>}
                        {(resendstate) && <p style={{color:'red'}}>需重寄驗證碼請先輸入帳號</p>}

                        <Button variant="primary" type="button"
                            onClick={handleRegisterConfirmation}>驗證</Button>
                        &nbsp;&nbsp;
                        <Button variant="outline-primary" type="button"
                            onClick={resendAuthenticationCode}>重新寄送驗證碼</Button>
                        &nbsp;&nbsp;
                        <Link
                            to='/'>
                            <Button variant="outline-primary">回首頁</Button>
                        </Link>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default ValidatePage;