import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react'

import { Auth, API, graphqlOperation } from 'aws-amplify'
import { usersByAccount, getServicepredict } from '../../graphql/queries'
import { updateUser } from '../../graphql/mutations'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function HomePage(props) {

    const [user, setUser] = useState([])

    const [loading, setLoading] = useState(true)
    
    const [servicedatastate, setServiceDataState] = useState(false)

    const getPoints = async () => {
        try {
            let todaydate = new Date().getDate()
            const user = await Auth.currentAuthenticatedUser()
            const username = user.username
            const listUserData = await API.graphql(graphqlOperation(usersByAccount,{account:username}))
            const userdata = listUserData.data.usersByAccount.items
            let pointdatetime = new Date(userdata[0].updatedAt).getDate()
            setUser(userdata)
            if (userdata[0].dailypoints !== 0 && todaydate !== pointdatetime){
                const newuserdata = {
                    id: userdata[0].id,
                    account: userdata[0].account,
                    points: userdata[0].points,
                    dailypoints: 0
                }
                await API.graphql(graphqlOperation(updateUser, {input: newuserdata}));
            }
        } catch (err) {
            console.error(err)
        }
    }

    const getServiceForm = async () => {
        try{
            const user = await Auth.currentAuthenticatedUser()
            const listData = await API.graphql(graphqlOperation(getServicepredict,{account:user.username}))
            if (listData.data.getServicepredict !== null){
                setServiceDataState(true)
            }else{
                setServiceDataState(false)
            }
        }catch(err){
            setServiceDataState(false)
        }
    }

    useEffect(() => {
        {
            getPoints()
            getServiceForm()
            const fetchData = async () => {
                await new Promise(resolve => setTimeout(resolve, 1000)); // 模擬 1 秒的請求延遲
                setLoading(false); // 將載入中狀態設置為 false，表示請求完成
            };
            fetchData();
        }
    }, []);

    return (
        <Container>
            {loading ? (
                <></>
            ) : (
                <Row className="px-4 my-5">
                <Col sm={6}>
                    <h1 className="font-weight-light">歡迎來到長照百寶箱</h1>
                    {
                        props.isAuthenticated === false && (
                            <>
                                <Link
                                    to='/login'>
                                    <Button variant="outline-primary">登入</Button>
                                </Link>
                                &nbsp;&nbsp;
                                <Link
                                    to='/register'>
                                    <Button variant="outline-primary">註冊</Button>
                                </Link>
                                <br/><br/>
                                <Link
                                    to='/guestCSIbasedform'>
                                    <Button variant="outline-primary">負荷指數評估</Button>
                                </Link>
                                <br/><br/>
                                <Link
                                    to='/guestserviceform'>
                                    <Button variant="outline-primary">服務預測評估</Button>
                                </Link>
                            </>
                        )
                    }
                    {
                        props.isAuthenticated !== false && (
                            <>
                                {
                                user.map((user,index) =>{
                                    return(
                                        <p style={{position:'fixed', top:'10%', left:'75%'}}>目前點數:{user.points}</p>
                                    )
                                })
                                }
                                <Link
                                    to='/CSIbasedform' state={{ authenticated: props.isAuthenticated }}>
                                    <Button variant="outline-primary">負荷指數評估</Button>
                                </Link>
                                &nbsp;&nbsp;
                                <Link
                                    to='/CSIbasedformrecords' state={{ authenticated: props.isAuthenticated }}>
                                    <Button variant="outline-primary">負荷指數歷程</Button>
                                </Link>
                                
                                <br/><br/>
                                
                                {
                                servicedatastate === false && <Link
                                    to='/serviceform' state={{ authenticated: props.isAuthenticated }}>
                                    <Button variant="outline-primary" hidden={servicedatastate}>服務預測評估</Button>
                                </Link>
                                }

                                {
                                servicedatastate === true && <Link
                                    to='/changeserviceform' state={{ authenticated: props.isAuthenticated }}>
                                    <Button variant="outline-primary" hidden={!servicedatastate}>修改服務預測評估</Button>
                                </Link>
                                }
                                
                                &nbsp;&nbsp;
                                <Link
                                    to='/servicepredictresult' state={{ authenticated: props.isAuthenticated }}>
                                    <Button variant="outline-primary">服務預測結果歷程</Button>
                                </Link>
                            </>
                        )
                    }
                </Col>
            </Row>
            )
            }
        </Container >
    )
}

export default HomePage;