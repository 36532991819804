import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { usersByAccount } from '../../graphql/queries'
import { createCsibasedForm, updateUser } from '../../graphql/mutations'
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { v4 as uuid } from 'uuid'

function FormPage (props){
    const navigate = useNavigate()
    
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})
    const [user, setUser] = useState([])

    const [show, setShow] = useState(false)
    const [pointshow, setPointsShow] = useState(false)

    const handleClose = () => setShow(false)
    const handlepointClose = () => setPointsShow(false)

    const getUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser()
            setField('username',user.username)
            const listUserData = await API.graphql(graphqlOperation(usersByAccount,{account:user.username}))
            const userdata = listUserData.data.usersByAccount.items
            setUser(userdata)
            if (userdata[0].dailypoints === 500){
                setPointsShow(true)
            }
        } catch (err) {
            console.error(err)
        }
    }

    const setField = (field, value) =>{
        setForm({
            ...form,
            [field]: value
        })
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            })
        }
    }

    const findFormErrors = () => {
        const {username, Q1, Q2, Q3, Q4, Q5, Q6, Q7, Q8, Q9, score} = form
        const newErrors = {}

        if (!Q1 || Q1 === '' ){
            newErrors.Q1 = '請選擇您的答案'
        }

        if (!Q2 || Q2 === '' ){
            newErrors.Q2 = '請選擇您的答案'
        }
        if (!Q3 || Q3 === '' ){
            newErrors.Q3 = '請選擇您的答案'
        }
        if (!Q4 || Q4 === '' ){
            newErrors.Q4 = '請選擇您的答案'
        }
        if (!Q5 || Q5 === '' ){
            newErrors.Q5 = '請選擇您的答案'
        }
        if (!Q6 || Q6 === '' ){
            newErrors.Q6 = '請選擇您的答案'
        }
        if (!Q7 || Q7 === '' ){
            newErrors.Q7 = '請選擇您的答案'
        }
        if (!Q8 || Q8 === '' ){
            newErrors.Q8 = '請選擇您的答案'
        }
        if (!Q9 || Q9 === '' ){
            newErrors.Q9 = '請選擇您的答案'
        }

        return newErrors
      }
    
    useEffect(() => {
        {
            getUser()
            props.isAuthenticated !== true && (
                navigate('/')
            )
        }
    }, []);

    const handleForm = async () => {
        const newErrors = findFormErrors()
        if ( Object.keys(newErrors).length > 0 ){
            setErrors(newErrors)
        }else{
            form.score = Number(form.Q1)+Number(form.Q2)+Number(form.Q3)+Number(form.Q4)+Number(form.Q5)+Number(form.Q6)+Number(form.Q7)+Number(form.Q8)+Number(form.Q9)
            form.score = String(form.score)
            try {
                const { username, Q1, Q2, Q3, Q4, Q5, Q6, Q7, Q8, Q9, score } = form
                const newformdata = {
                    id: uuid(),
                    username,
                    Q1,
                    Q2,
                    Q3,
                    Q4,
                    Q5,
                    Q6,
                    Q7,
                    Q8,
                    Q9,
                    score
                }
                console.log('submit')
                await API.graphql(graphqlOperation(createCsibasedForm, {input: newformdata}));
                if (user[0].dailypoints < 500){
                    setShow(true)
                    const newuserdata = {
                        id: user[0].id,
                        account: user[0].account,
                        points: user[0].points + 100,
                        dailypoints: user[0].dailypoints + 100
                    }
                    await API.graphql(graphqlOperation(updateUser, {input: newuserdata}));
                }
                navigate('/formrecords')
            } catch (err) { 
                console.log('error') 
                console.log(err.message) 
            }
        }
    }

    return(
        <Container>
            <Row className="px-4 my-5">
                <Col><h1>照顧負荷量表(CSI-based score)</h1></Col>
            </Row>
            <Row className="px-4 my-5">
                <Col sm={10}>
                    <Form>
                        <Form.Label>1.若您有事必須外出，一天之中（二十四小時），請問您可將個案單獨留置家中幾小時沒有問題？</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="九小時以上" 
                                name="Q1" 
                                value={0}
                                onChange={evt => setField('Q1',evt.target.value)}
                                isInvalid={!!errors.Q1}
                                feedback={errors.Q1}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="六小時～未滿九小時" 
                                name="Q1"  
                                value={0.2}
                                onChange={evt => setField('Q1',evt.target.value)}
                                isInvalid={!!errors.Q1}
                                feedback={errors.Q1}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="三小時～未滿六小時" 
                                name="Q1"  
                                value={0.4}
                                onChange={evt => setField('Q1',evt.target.value)}
                                isInvalid={!!errors.Q1}
                                feedback={errors.Q1}
                            />
                            <br/>
                            <Form.Check 
                                inline
                                type="radio" 
                                label="一小時～未滿三小時" 
                                name="Q1"  
                                value={0.6}
                                onChange={evt => setField('Q1',evt.target.value)}
                                isInvalid={!!errors.Q1}
                                feedback={errors.Q1}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="未滿一小時" 
                                name="Q1"  
                                value={0.8}
                                onChange={evt => setField('Q1',evt.target.value)}
                                isInvalid={!!errors.Q1}
                                feedback={errors.Q1}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="個案不能一個人在家" 
                                name="Q1"  
                                value={1}
                                onChange={evt => setField('Q1',evt.target.value)}
                                isInvalid={!!errors.Q1}
                                feedback={errors.Q1}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.Q1}
                                feedback={errors.Q1}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>2.體力上的負荷(例如：需專注看護病者或費力去幫助病者坐立)</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="是" 
                                name="Q2" 
                                value={1}
                                onChange={evt => setField('Q2',evt.target.value)}
                                isInvalid={!!errors.Q2}
                                feedback={errors.Q2}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="否" 
                                name="Q2"  
                                value={0}
                                onChange={evt => setField('Q2',evt.target.value)}
                                isInvalid={!!errors.Q2}
                                feedback={errors.Q2}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.Q2}
                                feedback={errors.Q2}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>3.睡眠受到干擾(例如：需在夜間照顧經常要上落床或無法安頓下來的病者)</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="是" 
                                name="Q3" 
                                value={1}
                                onChange={evt => setField('Q3',evt.target.value)}
                                isInvalid={!!errors.Q3}
                                feedback={errors.Q3}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="否" 
                                name="Q3"  
                                value={0}
                                onChange={evt => setField('Q3',evt.target.value)}
                                isInvalid={!!errors.Q3}
                                feedback={errors.Q3}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.Q3}
                                feedback={errors.Q3}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>4.整體來說，您覺得您生活品質好不好？</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="非常不好" 
                                name="Q4" 
                                value={1}
                                onChange={evt => setField('Q4',evt.target.value)}
                                isInvalid={!!errors.Q4}
                                feedback={errors.Q4}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="不好" 
                                name="Q4"  
                                value={0.8}
                                onChange={evt => setField('Q4',evt.target.value)}
                                isInvalid={!!errors.Q4}
                                feedback={errors.Q4}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="尚可" 
                                name="Q4"  
                                value={0.6}
                                onChange={evt => setField('Q4',evt.target.value)}
                                isInvalid={!!errors.Q4}
                                feedback={errors.Q4}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="好" 
                                name="Q4"  
                                value={0.4}
                                onChange={evt => setField('Q4',evt.target.value)}
                                isInvalid={!!errors.Q4}
                                feedback={errors.Q4}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="很好" 
                                name="Q4"  
                                value={0.2}
                                onChange={evt => setField('Q4',evt.target.value)}
                                isInvalid={!!errors.Q4}
                                feedback={errors.Q4}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="非常好" 
                                name="Q4"  
                                value={0}
                                onChange={evt => setField('Q4',evt.target.value)}
                                isInvalid={!!errors.Q4}
                                feedback={errors.Q4}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.Q4}
                                feedback={errors.Q4}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>5.需要分配時間照顧其他家人</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="是" 
                                name="Q5" 
                                value={1}
                                onChange={evt => setField('Q5',evt.target.value)}
                                isInvalid={!!errors.Q5}
                                feedback={errors.Q5}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="否" 
                                name="Q5"  
                                value={0}
                                onChange={evt => setField('Q5',evt.target.value)}
                                isInvalid={!!errors.Q5}
                                feedback={errors.Q5}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.Q5}
                                feedback={errors.Q5}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>6.對個案的行為感到困擾(例如：失禁、他記憶事情有困難或他怪責別人取了他的東西)</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="是" 
                                name="Q6" 
                                value={1}
                                onChange={evt => setField('Q6',evt.target.value)}
                                isInvalid={!!errors.Q6}
                                feedback={errors.Q6}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="否" 
                                name="Q6"  
                                value={0}
                                onChange={evt => setField('Q6',evt.target.value)}
                                isInvalid={!!errors.Q6}
                                feedback={errors.Q6}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.Q6}
                                feedback={errors.Q6}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>7.發現病者改變很大而令你不安</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="是" 
                                name="Q7" 
                                value={1}
                                onChange={evt => setField('Q7',evt.target.value)}
                                isInvalid={!!errors.Q7}
                                feedback={errors.Q7}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="否" 
                                name="Q7"  
                                value={0}
                                onChange={evt => setField('Q7',evt.target.value)}
                                isInvalid={!!errors.Q7}
                                feedback={errors.Q7}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.Q7}
                                feedback={errors.Q7}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>8.為了照顧個案，您的工作是否受到影響</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="是" 
                                name="Q8" 
                                value={1}
                                onChange={evt => setField('Q8',evt.target.value)}
                                isInvalid={!!errors.Q8}
                                feedback={errors.Q8}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="否" 
                                name="Q8"  
                                value={0}
                                onChange={evt => setField('Q8',evt.target.value)}
                                isInvalid={!!errors.Q8}
                                feedback={errors.Q8}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.Q8}
                                feedback={errors.Q8}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>9.是否感到心靈疲累(例如：因為擔心或顧慮該如何處理病者)</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="是" 
                                name="Q9" 
                                value={1}
                                onChange={evt => setField('Q9',evt.target.value)}
                                isInvalid={!!errors.Q9}
                                feedback={errors.Q9}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="否" 
                                name="Q9"  
                                value={0}
                                onChange={evt => setField('Q9',evt.target.value)}
                                isInvalid={!!errors.Q9}
                                feedback={errors.Q9}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.Q9}
                                feedback={errors.Q9}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Button variant="primary" type="button"
                            onClick={handleForm}>送出</Button>
                        
                        <Modal show={pointshow} onHide={handlepointClose}>
                            <Modal.Header>
                                <Modal.Title>點數提醒</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>每日最多500點，今日已無法再獲取點數。</Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={handlepointClose}>關閉</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                            <Modal.Title>點數提醒</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>獲得100點數</Modal.Body>
                        </Modal>

                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default FormPage;