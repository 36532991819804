import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { Auth } from 'aws-amplify';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function ForgetPasswordPage() {
    const navigate = useNavigate()
    
    const [username, setUserName] = useState('')
    const [blankstate, setblankState] = useState(true)
    const [errorstate, seterrorState] = useState(true)

    const forgotPassword = async () => {
        try {

            await Auth.forgotPassword(username)
            navigate('/forgetPasswordReset')
        } catch (err) {
             console.log(err.message)
             if (username === ''){
                setblankState(false)
                seterrorState(true)
             }else{
                seterrorState(false)
                setblankState(true)
             }
        }
    }

    return (
        <Container>
            <Row className="px-4 my-5">
                <Col><h1>忘記密碼</h1></Col>
            </Row>
            <Row className="px-4 my-5">
                <Col sm={6}>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>帳號</Form.Label>
                            <Form.Control type="text"
                                onChange={evt => setUserName(evt.target.value)} />
                        </Form.Group>

                        {(!blankstate) && <p style={{color:'red'}}>帳號空白，請重新輸入</p>}
                        {(!errorstate) && <p style={{color:'red'}}>帳號錯誤，請重新輸入</p>}
                        
                        <Button variant="primary" type="button"
                            onClick={forgotPassword}>送出</Button>
                        &nbsp;&nbsp;
                        <Link
                            to='/login'>
                            <Button variant="outline-primary">返回登入</Button>
                        </Link>
                        &nbsp;&nbsp;
                        <Link
                            to='/'>
                            <Button variant="outline-primary">回首頁</Button>
                        </Link>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default ForgetPasswordPage;