import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { createGuestcsibasedForm } from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuid } from 'uuid'

import Data from '../../data.csv'
import Papa from 'papaparse'

function GuestFormPage (){
    
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const [data, setData] = useState([])

    const [guestshow, setguestShow] = useState(true)
    const handleguestClose = () => setguestShow(false)
    const [submitState, setsubmitState] = useState(false)


    const setField = (field, value) =>{
        setForm({
            ...form,
            [field]: value
        })
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            })
        }
    }

    const findFormErrors = () => {
        const { gender, AGE, Q1, Q2, Q3, Q4, Q5, Q6, Q7, Q8, Q9, score } = form
        const newErrors = {}

        if (!gender || gender === '' ){
            newErrors.gender = '請選擇您的答案'
        }

        if (!AGE || AGE === '' ){
            newErrors.AGE = '此欄位不能空白'
        }else if (AGE < 18 || AGE > 99){
            newErrors.AGE = '請介於18~99之間'
        }else if (!AGE.match(/(^(?=.*\d).{2,2}$)/)){
            newErrors.AGE = '請不要包含任何英文或特殊符號'
        }

        if (!Q1 || Q1 === '' ){
            newErrors.Q1 = '請選擇您的答案'
        }

        if (!Q2 || Q2 === '' ){
            newErrors.Q2 = '請選擇您的答案'
        }
        if (!Q3 || Q3 === '' ){
            newErrors.Q3 = '請選擇您的答案'
        }
        if (!Q4 || Q4 === '' ){
            newErrors.Q4 = '請選擇您的答案'
        }
        if (!Q5 || Q5 === '' ){
            newErrors.Q5 = '請選擇您的答案'
        }
        if (!Q6 || Q6 === '' ){
            newErrors.Q6 = '請選擇您的答案'
        }
        if (!Q7 || Q7 === '' ){
            newErrors.Q7 = '請選擇您的答案'
        }
        if (!Q8 || Q8 === '' ){
            newErrors.Q8 = '請選擇您的答案'
        }
        if (!Q9 || Q9 === '' ){
            newErrors.Q9 = '請選擇您的答案'
        }

        return newErrors
      }

    const handleForm = async () => {
        const newErrors = findFormErrors()
        if ( Object.keys(newErrors).length > 0 ){
            setErrors(newErrors)
        }else{
            form.score = Number(form.Q1)+Number(form.Q2)+Number(form.Q3)+Number(form.Q4)+Number(form.Q5)+Number(form.Q6)+Number(form.Q7)+Number(form.Q8)+Number(form.Q9)
            form.score = String(form.score)
            try {
                const { Q1, Q2, Q3, Q4, Q5, Q6, Q7, Q8, Q9, score } = form
                const newformdata = {
                    id: uuid(),
                    Q1,
                    Q2,
                    Q3,
                    Q4,
                    Q5,
                    Q6,
                    Q7,
                    Q8,
                    Q9,
                    score
                }
                console.log('submit')
                await API.graphql(graphqlOperation(createGuestcsibasedForm, {input: newformdata}));
                setsubmitState(true)
            } catch (err) { 
                console.log('error') 
                console.error(err) 
            }
        }
    }

    useEffect(() => {
        {
            const fetchData = async () => {
                const response = await fetch(Data)
                const reader = response.body.getReader()
                const result = await reader.read()
                const decoder = new TextDecoder("utf-8")
                const csvData = decoder.decode(result.value)
                const parsedData = Papa.parse(csvData, { 
                    header: true, 
                    skipEmptyLines: true 
                }).data;
                setData(parsedData)
                };
                fetchData()
        }
    }, [])

    const calculateScoreindex = (score) =>{
        let scoreindex = 1
        let age_group = ''
        if (form.AGE <= 30){
            age_group = '18-30'
        }else if(form.AGE  >= 31 && form.AGE  <=50){
            age_group = '31-50'
        }else if(form.AGE  >= 51 && form.AGE  <=65){
            age_group = '51-65'
        }else{
            age_group = '66-99'
        }
        for (let i=0;i<=data.length-1;i++){
            if(age_group === data[i].Caregiver_AgeGroup && form.gender === data[i].Caregiver_SEX && score > data[i].CSI_TOTAL_SCORE){
                scoreindex += 1
            }
        }
        return scoreindex
    }

    function renderForm() {
        return(
            <Container>
                <Row className="px-4 my-5">
                    <Col><h1>照顧負荷量表(CSI-based score)</h1></Col>
                </Row>
                <Row className="px-4 my-5">
                    <Col sm={10}>
                        <Form>
                            <Form.Label>請選擇您的性別</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="男" 
                                    name="gender" 
                                    value='男'
                                    onChange={evt => setField('gender',evt.target.value)}
                                    isInvalid={!!errors.gender}
                                    feedback={errors.gender}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="女" 
                                    name="gender"  
                                    value='女'
                                    onChange={evt => setField('gender',evt.target.value)}
                                    isInvalid={!!errors.gender}
                                    feedback={errors.gender}
                                />
                                <Form.Check
                                    type="radio"
                                    hidden
                                    isInvalid={!!errors.gender}
                                    feedback={errors.gender}
                                    feedbackType="invalid"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                            <Form.Label>請輸入您的年齡</Form.Label>
                            <Form.Control type="text" required
                                onChange={evt => setField('AGE',evt.target.value)}
                                isInvalid={!!errors.AGE}/>
                            <Form.Control.Feedback type='invalid'>{ errors.AGE }</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Label>1.若您有事必須外出，一天之中（二十四小時），請問您可將個案單獨留置家中幾小時沒有問題？</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="九小時以上" 
                                    name="Q1" 
                                    value={0}
                                    onChange={evt => setField('Q1',evt.target.value)}
                                    isInvalid={!!errors.Q1}
                                    feedback={errors.Q1}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="六小時～未滿九小時" 
                                    name="Q1"  
                                    value={0.2}
                                    onChange={evt => setField('Q1',evt.target.value)}
                                    isInvalid={!!errors.Q1}
                                    feedback={errors.Q1}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="三小時～未滿六小時" 
                                    name="Q1"  
                                    value={0.4}
                                    onChange={evt => setField('Q1',evt.target.value)}
                                    isInvalid={!!errors.Q1}
                                    feedback={errors.Q1}
                                />
                                <br/>
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="一小時～未滿三小時" 
                                    name="Q1"  
                                    value={0.6}
                                    onChange={evt => setField('Q1',evt.target.value)}
                                    isInvalid={!!errors.Q1}
                                    feedback={errors.Q1}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="未滿一小時" 
                                    name="Q1"  
                                    value={0.8}
                                    onChange={evt => setField('Q1',evt.target.value)}
                                    isInvalid={!!errors.Q1}
                                    feedback={errors.Q1}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="個案不能一個人在家" 
                                    name="Q1"  
                                    value={1}
                                    onChange={evt => setField('Q1',evt.target.value)}
                                    isInvalid={!!errors.Q1}
                                    feedback={errors.Q1}
                                />
                                <Form.Check
                                    type="radio"
                                    hidden
                                    isInvalid={!!errors.Q1}
                                    feedback={errors.Q1}
                                    feedbackType="invalid"
                                />
                            </Form.Group>
    
                            <Form.Label>2.體力上的負荷(例如：需專注看護病者或費力去幫助病者坐立)</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="是" 
                                    name="Q2" 
                                    value={1}
                                    onChange={evt => setField('Q2',evt.target.value)}
                                    isInvalid={!!errors.Q2}
                                    feedback={errors.Q2}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="否" 
                                    name="Q2"  
                                    value={0}
                                    onChange={evt => setField('Q2',evt.target.value)}
                                    isInvalid={!!errors.Q2}
                                    feedback={errors.Q2}
                                />
                                <Form.Check
                                    type="radio"
                                    hidden
                                    isInvalid={!!errors.Q2}
                                    feedback={errors.Q2}
                                    feedbackType="invalid"
                                />
                            </Form.Group>
    
                            <Form.Label>3.睡眠受到干擾(例如：需在夜間照顧經常要上落床或無法安頓下來的病者)</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="是" 
                                    name="Q3" 
                                    value={1}
                                    onChange={evt => setField('Q3',evt.target.value)}
                                    isInvalid={!!errors.Q3}
                                    feedback={errors.Q3}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="否" 
                                    name="Q3"  
                                    value={0}
                                    onChange={evt => setField('Q3',evt.target.value)}
                                    isInvalid={!!errors.Q3}
                                    feedback={errors.Q3}
                                />
                                <Form.Check
                                    type="radio"
                                    hidden
                                    isInvalid={!!errors.Q3}
                                    feedback={errors.Q3}
                                    feedbackType="invalid"
                                />
                            </Form.Group>
    
                            <Form.Label>4.整體來說，您覺得您生活品質好不好？</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="非常不好" 
                                    name="Q4" 
                                    value={1}
                                    onChange={evt => setField('Q4',evt.target.value)}
                                    isInvalid={!!errors.Q4}
                                    feedback={errors.Q4}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="不好" 
                                    name="Q4"  
                                    value={0.8}
                                    onChange={evt => setField('Q4',evt.target.value)}
                                    isInvalid={!!errors.Q4}
                                    feedback={errors.Q4}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="尚可" 
                                    name="Q4"  
                                    value={0.6}
                                    onChange={evt => setField('Q4',evt.target.value)}
                                    isInvalid={!!errors.Q4}
                                    feedback={errors.Q4}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="好" 
                                    name="Q4"  
                                    value={0.4}
                                    onChange={evt => setField('Q4',evt.target.value)}
                                    isInvalid={!!errors.Q4}
                                    feedback={errors.Q4}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="很好" 
                                    name="Q4"  
                                    value={0.2}
                                    onChange={evt => setField('Q4',evt.target.value)}
                                    isInvalid={!!errors.Q4}
                                    feedback={errors.Q4}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="非常好" 
                                    name="Q4"  
                                    value={0}
                                    onChange={evt => setField('Q4',evt.target.value)}
                                    isInvalid={!!errors.Q4}
                                    feedback={errors.Q4}
                                />
                                <Form.Check
                                    type="radio"
                                    hidden
                                    isInvalid={!!errors.Q4}
                                    feedback={errors.Q4}
                                    feedbackType="invalid"
                                />
                            </Form.Group>
    
                            <Form.Label>5.需要分配時間照顧其他家人</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="是" 
                                    name="Q5" 
                                    value={1}
                                    onChange={evt => setField('Q5',evt.target.value)}
                                    isInvalid={!!errors.Q5}
                                    feedback={errors.Q5}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="否" 
                                    name="Q5"  
                                    value={0}
                                    onChange={evt => setField('Q5',evt.target.value)}
                                    isInvalid={!!errors.Q5}
                                    feedback={errors.Q5}
                                />
                                <Form.Check
                                    type="radio"
                                    hidden
                                    isInvalid={!!errors.Q5}
                                    feedback={errors.Q5}
                                    feedbackType="invalid"
                                />
                            </Form.Group>
    
                            <Form.Label>6.對個案的行為感到困擾(例如：失禁、他記憶事情有困難或他怪責別人取了他的東西)</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="是" 
                                    name="Q6" 
                                    value={1}
                                    onChange={evt => setField('Q6',evt.target.value)}
                                    isInvalid={!!errors.Q6}
                                    feedback={errors.Q6}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="否" 
                                    name="Q6"  
                                    value={0}
                                    onChange={evt => setField('Q6',evt.target.value)}
                                    isInvalid={!!errors.Q6}
                                    feedback={errors.Q6}
                                />
                                <Form.Check
                                    type="radio"
                                    hidden
                                    isInvalid={!!errors.Q6}
                                    feedback={errors.Q6}
                                    feedbackType="invalid"
                                />
                            </Form.Group>
    
                            <Form.Label>7.發現病者改變很大而令你不安</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="是" 
                                    name="Q7" 
                                    value={1}
                                    onChange={evt => setField('Q7',evt.target.value)}
                                    isInvalid={!!errors.Q7}
                                    feedback={errors.Q7}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="否" 
                                    name="Q7"  
                                    value={0}
                                    onChange={evt => setField('Q7',evt.target.value)}
                                    isInvalid={!!errors.Q7}
                                    feedback={errors.Q7}
                                />
                                <Form.Check
                                    type="radio"
                                    hidden
                                    isInvalid={!!errors.Q7}
                                    feedback={errors.Q7}
                                    feedbackType="invalid"
                                />
                            </Form.Group>
    
                            <Form.Label>8.為了照顧個案，您的工作是否受到影響</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Check inline
                                    type="radio" 
                                    label="是" 
                                    name="Q8" 
                                    value={1}
                                    onChange={evt => setField('Q8',evt.target.value)}
                                    isInvalid={!!errors.Q8}
                                    feedback={errors.Q8}
                                />
                                <Form.Check inline
                                    type="radio" 
                                    label="否" 
                                    name="Q8"  
                                    value={0}
                                    onChange={evt => setField('Q8',evt.target.value)}
                                    isInvalid={!!errors.Q8}
                                    feedback={errors.Q8}
                                />
                                <Form.Check
                                    type="radio"
                                    hidden
                                    isInvalid={!!errors.Q8}
                                    feedback={errors.Q8}
                                    feedbackType="invalid"
                                />
                            </Form.Group>
    
                            <Form.Label>9.是否感到心靈疲累(例如：因為擔心或顧慮該如何處理病者)</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="是" 
                                    name="Q9" 
                                    value={1}
                                    onChange={evt => setField('Q9',evt.target.value)}
                                    isInvalid={!!errors.Q9}
                                    feedback={errors.Q9}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="否" 
                                    name="Q9"  
                                    value={0}
                                    onChange={evt => setField('Q9',evt.target.value)}
                                    isInvalid={!!errors.Q9}
                                    feedback={errors.Q9}
                                />
                                <Form.Check
                                    type="radio"
                                    hidden
                                    isInvalid={!!errors.Q9}
                                    feedback={errors.Q9}
                                    feedbackType="invalid"
                                />
                            </Form.Group>
    
                            <Button variant="primary" type="button"
                                onClick={handleForm}>送出</Button>

                            <Modal show={guestshow} onHide={handleguestClose}>
                                <Modal.Header closeButton>
                                <Modal.Title>訪客提醒</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>此為訪客試用，如需紀錄您的負荷指數，請註冊會員。</Modal.Body>
                            </Modal>

                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }

    function renderformresult() {
        return(
            <Container>
                <Row className="px-4 my-5">
                    <Col sm={12}>
                        <b><h3>與您年齡相近且同樣為{form.gender}性的照顧者中，您的照顧負荷高於{calculateScoreindex(form.score)}%的人</h3></b>
                        <b><p style={{color : 'red',fontSize: '25px'}}>※請注意，比率越高代表您的負荷也越大</p></b>
                        <a href="https://carersupport.com.tw/" target="_blank" style={{fontSize: '20px'}}>您累了嗎？點選取得家庭照顧者服務資源</a>
                        <br/><br/>
                        <p>問題1.若您有事必須外出，一天之中（二十四小時），請問您可將個案單獨留置家中幾小時沒有問題？</p>
                        {form.Q1 === "0" && (<p>您的答案為:九小時以上</p>)}
                        {form.Q1 === "0.2" && (<p>您的答案為:六小時～未滿九小時</p>)}
                        {form.Q1 === "0.4" && (<p>您的答案為:三小時～未滿六小時</p>)}
                        {form.Q1 === "0.6" && (<p>您的答案為:一小時～未滿三小時</p>)}
                        {form.Q1 === "0.8" && (<p>您的答案為:未滿一小時</p>)}
                        {form.Q1 === "1" && (<p>您的答案為:個案不能一個人在家</p>)}
                        <p>問題2.體力上的負荷(例如：需專注看護病者或費力去幫助病者坐立)</p>
                        {form.Q2 === "0" && (<p>您的答案為:否</p>)}
                        {form.Q2 === "1" && (<p>您的答案為:是</p>)}
                        <p>問題3.睡眠受到干擾(例如：需在夜間照顧經常要上落床或無法安頓下來的病者)</p>
                        {form.Q3 === "0" && (<p>您的答案為:否</p>)}
                        {form.Q3 === "1" && (<p>您的答案為:是</p>)}
                        <p>問題4.整體來說，您覺得您生活品質好不好？</p>
                        {form.Q4 === "0" && (<p>您的答案為:非常好</p>)}
                        {form.Q4 === "0.2" && (<p>您的答案為:很好</p>)}
                        {form.Q4 === "0.4" && (<p>您的答案為:好</p>)}
                        {form.Q4 === "0.6" && (<p>您的答案為:尚可</p>)}
                        {form.Q4 === "0.8" && (<p>您的答案為:不好</p>)}
                        {form.Q4 === "1" && (<p>您的答案為:非常不好</p>)}
                        <p>問題5.需要分配時間照顧其他家人</p>
                        {form.Q5 === "0" && (<p>您的答案為:否</p>)}
                        {form.Q5 === "1" && (<p>您的答案為:是</p>)}
                        <p>問題6.對個案的行為感到困擾(例如：失禁、他記憶事情有困難或他怪責別人取了他的東西)</p>
                        {form.Q6 === "0" && (<p>您的答案為:否</p>)}
                        {form.Q6 === "1" && (<p>您的答案為:是</p>)}
                        <p>問題7.發現病者改變很大而令你不安</p>
                        {form.Q7 === "0" && (<p>您的答案為:否</p>)}
                        {form.Q7 === "1" && (<p>您的答案為:是</p>)}
                        <p>問題8.為了照顧個案，您的工作是否受到影響</p>
                        {form.Q8 === "0" && (<p>您的答案為:否</p>)}
                        {form.Q8 === "1" && (<p>您的答案為:是</p>)}
                        <p>問題9.是否感到心靈疲累(例如：因為擔心或顧慮該如何處理病者)</p>
                        {form.Q9 === "0" && (<p>您的答案為:否</p>)}
                        {form.Q9 === "1" && (<p>您的答案為:是</p>)}
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <Container>
            {submitState === false ? renderForm() : renderformresult()}
        </Container>
    )
}

export default GuestFormPage;